import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'it_support',
  formId: 'UK%20IT%20Support',
  title: 'It Support',
  subTitle: '“Have you tried turning it off and on again?”',
  icon: '/icons/it_support.svg',
  image: '/assets/images/sunrise/it_support_supermercat.png',
  theme: 'sunrise-it_support',
  sections: [
    {
      sectionId: 'hero',
      label: 'IT Support',
      title: '“Have you tried turning it off and on again?”',
      description:
        'Having ongoing problems with your PC or Mac, headaches when backing up data or just a yearning for a personal IT service desk so you don’t waste your own tech-guys’ time? Look no further.',
      image: '/assets/images/sunrise/it_support_supermercat.png',
      icon: '/icons/it_support.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'You need IT - we bring IT',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/it_support/service_1.svg',
          title: 'Backup and mail systems',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_2.svg',
          title: 'IT Service',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_3.svg',
          title: 'WiFi',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_4.svg',
          title: 'IT Hardware',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'A few steps and IT help is on its way',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/it_support/journey_1.svg',
          title: 'Tell us what’s up',
          description: 'Let us know your struggle or what you want to get out of IT support.',
        },
        {
          icon: '/assets/images/services/it_support/journey_2.svg',
          title: 'Quick meeting',
          description: 'You will meet our IT partner who makes sure all your needs are met.',
        },
        {
          icon: '/assets/images/services/it_support/journey_3.svg',
          title: 'Receive proposal',
          description: 'We will work together with our IT partners to get the best price for your buck.',
        },
        {
          icon: '/assets/images/services/it_support/journey_4.svg',
          title: 'Plugged in',
          description: 'You’re now ready to be supported!!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love IT support from Good Monday!',
      description: '',
      image: '/assets/images/services/it_support/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-umbrella-outline',
          title: 'Protect your tech team',
          description:
            'Often the in-house tech team also runs the internal IT setup which is draining development and your workmates.',
        },
        {
          icon: 'eva eva-pie-chart-outline',
          title: 'Save an FTE',
          description:
            'When having IT support through us you don’t have to think about extra employee benefits and you’ll have full flexibility.',
        },
        {
          icon: 'eva eva-power-outline',
          title: 'Ready to go from the get-go',
          description: 'An army of professionals who do this everyday. We’ll make sure you see immediate results.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday you can get your own in-house IT support center?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
